import React, { useState } from "react"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"

const NegativeReviewData = props => {
  document.title = "Negative Reviews | Merchant Dashboard"

  const [reviews, setReviews] = useState([
    {
      id: 1,
      name: "Alice Johnson",
      mobile: "123-456-7890",
      review: "The product did not meet my expectations.",
      rating: 2,
      date: "2024-09-26",
      status: "Open",
    },
    {
      id: 2,
      name: "Bob Smith",
      mobile: "098-765-4321",
      review: "Customer service was unhelpful.",
      rating: 1,
      date: "2024-09-25",
      status: "Open",
    },
    {
      id: 3,
      name: "David Williams",
      mobile: "222-333-4444",
      review: "The item was missing parts.",
      rating: 2,
      date: "2024-09-23",
      status: "Open",
    },
    {
      id: 4,
      name: "Emily Davis",
      mobile: "333-444-5555",
      review: "The quality is not as described.",
      rating: 3,
      date: "2024-09-22",
      status: "Open",
    },
    {
      id: 5,
      name: "Frank Moore",
      mobile: "444-555-6666",
      review: "I received the wrong item.",
      rating: 5,
      date: "2024-09-21",
      status: "Open",
    },
    {
      id: 6,
      name: "Charlie Brown",
      mobile: "111-222-3333",
      review: "Delivery was late and product was damaged.",
      rating: 1,
      date: "2024-09-24",
      status: "Open",
    },
  ])

  const [modal, setModal] = useState(false)
  const [selectedReview, setSelectedReview] = useState(null)
  const [resolutionDetails, setResolutionDetails] = useState("")
  const [status, setStatus] = useState("Open")
  const [searchTerm, setSearchTerm] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const [reviewsPerPage] = useState(5)

  const toggleModal = () => setModal(!modal)

  const markAsResolved = review => {
    setSelectedReview(review)
    toggleModal()
  }

  const handleResolve = () => {
    if (selectedReview) {
      const updatedReviews = reviews.map(review =>
        review.id === selectedReview.id
          ? { ...review, status: status, resolutionDetails }
          : review,
      )
      setReviews(updatedReviews)
    }
    toggleModal()
  }

  // Sort reviews by date in descending order (LIFO)
  const sortedReviews = reviews.sort(
    (a, b) => new Date(b.date) - new Date(a.date),
  )

  // Filter reviews by search term
  const filteredReviews = sortedReviews.filter(review =>
    review.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  // Get current reviews based on the current page and reviews per page
  const indexOfLastReview = currentPage * reviewsPerPage
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage
  const currentReviews = filteredReviews.slice(
    indexOfFirstReview,
    indexOfLastReview,
  )

  const paginate = pageNumber => setCurrentPage(pageNumber)

  const getStatusColor = status => {
    switch (status) {
      case "Open":
        return "text-danger"
      case "On Hold":
        return "text-warning"
      case "Escalated":
        return "text-warning"
      case "Resolved":
        return "text-success"
      case "Closed":
        return "text-muted"
      default:
        return ""
    }
  }

  const StarRating = ({ rating }) => {
    const totalStars = 5
    return (
      <>
        {Array.from({ length: totalStars }, (_, index) => (
          <i
            className="mdi mdi-star"
            key={index}
            style={{
              fontSize: "20px",
              color: index < rating ? "gold" : "#BEBEBE",
              marginRight: "3px",
            }}
          />
        ))}
      </>
    )
  }

  const pageNumbers = []
  for (
    let i = 1;
    i <= Math.ceil(filteredReviews.length / reviewsPerPage);
    i++
  ) {
    pageNumbers.push(i)
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col lg={6}>
                  <CardTitle className="h4">Negative Reviews Data</CardTitle>
                  <p className="card-title-desc">
                    Review and manage negative feedback efficiently.
                  </p>
                </Col>
                <Col lg={4} className="text-lg-right">
                  <Input
                    type="text"
                    placeholder="Search by Name"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                </Col>
              </Row>

              <div className="table-responsive">
                <Table className="table mb-0 d-none d-lg-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Review</th>
                      <th>Rating</th>
                      <th>Date</th>
                      <th>Action Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentReviews.map((review, index) => (
                      <tr key={review.id}>
                        <th scope="row">{indexOfFirstReview + index + 1}</th>
                        <td>{review.name}</td>
                        <td>{review.mobile}</td>
                        <td>{review.review}</td>
                        <td>
                          <StarRating rating={review.rating} />
                        </td>
                        <td>{review.date}</td>
                        <td className={getStatusColor(review.status)}>
                          {review.status}
                        </td>
                        <td>
                          <Button
                            color="link"
                            onClick={() => markAsResolved(review)}
                          >
                            <i
                              className="mdi mdi-pencil"
                              style={{ fontSize: "20px" }}
                              color="gray"
                            ></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {/* Responsive card view for smaller screens */}
                <div className="d-lg-none">
                  {currentReviews.map((review, index) => (
                    <Card className="mb-3" key={review.id}>
                      <CardBody>
                        <h5 className="card-title">
                          No.{indexOfFirstReview + index + 1}
                        </h5>
                        <p>
                          <strong>Name:</strong> {review.name}
                        </p>
                        <p>
                          <strong>Mobile:</strong> {review.mobile}
                        </p>
                        <p>
                          <strong>Review:</strong> {review.review}
                        </p>
                        <p>
                          <strong>Rating:</strong>{" "}
                          <StarRating rating={review.rating} />
                        </p>
                        <p>
                          <strong>Date:</strong> {review.date}
                        </p>
                        <p>
                          <strong>Action Status:</strong>{" "}
                          <span className={getStatusColor(review.status)}>
                            {" "}
                            {review.status}
                          </span>
                        </p>
                        <p>
                          <strong>Action:</strong>{" "}
                          <Button
                            color="link"
                            onClick={() => markAsResolved(review)}
                          >
                            <i
                              className="mdi mdi-pencil"
                              style={{ fontSize: "20px" }}
                              color="gray"
                            ></i>
                          </Button>
                        </p>
                      </CardBody>
                    </Card>
                  ))}
                </div>
              </div>

              {/* Pagination Component */}
              <div className="d-flex justify-content-end mt-3">
                <Pagination aria-label="Page navigation example">
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink first onClick={() => paginate(1)} />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => paginate(currentPage - 1)}
                    />
                  </PaginationItem>
                  {pageNumbers.map(number => (
                    <PaginationItem
                      active={number === currentPage}
                      key={number}
                    >
                      <PaginationLink onClick={() => paginate(number)}>
                        {number}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage === pageNumbers.length}>
                    <PaginationLink
                      next
                      onClick={() => paginate(currentPage + 1)}
                    />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage === pageNumbers.length}>
                    <PaginationLink
                      last
                      onClick={() => paginate(pageNumbers.length)}
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Resolve Review</ModalHeader>
        <ModalBody>
          <Label>Status:</Label>
          <Input
            type="select"
            value={status}
            onChange={e => setStatus(e.target.value)}
          >
            <option value="Open">Open</option>
            <option value="On Hold">On Hold</option>
            <option value="Escalated">Escalated</option>
            <option value="Resolved">Resolved</option>
            <option value="Closed">Closed</option>
          </Input>
          <Label className="mt-3">Resolution Details:</Label>
          <Input
            type="textarea"
            value={resolutionDetails}
            onChange={e => setResolutionDetails(e.target.value)}
            placeholder="Enter resolution details..."
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleResolve}>
            Resolve
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default NegativeReviewData
