
import React, { useState } from "react";
import user1 from "../../assets/images/users/user-1.jpg"; 

const UserProfile = (props) => {
  const [hasGst, setHasGst] = useState(true);
  const [name, setName] = useState("Admin"); 
  const [phone] = useState("+1-234-567-890"); 
  const [businessType, setBusinessType] = useState("Retail"); 
  const [gstNumber, setGstNumber] = useState(""); 
  const [address, setAddress] = useState("123 Main St, City, Country"); 
  const [email, setEmail] = useState("admin@example.com"); 
  const [errors, setErrors] = useState({});

  document.title = "Merchant Profile | Lexa - Responsive Bootstrap 5 Admin Dashboard";

  const handleGstToggle = (e) => {
    const checked = e.target.checked;
    setHasGst(!checked);
    if (checked) {
      setGstNumber(""); // Clear GST number when unchecked
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!/^[a-zA-Z ]*$/.test(name)) newErrors.name = "Only alphabets are allowed";

    if (!businessType) newErrors.businessType = "Business type is required";
    
    // GST validation
    if (hasGst && !gstNumber) {
      newErrors.gstNumber = "GST number is required";
    } else if (hasGst && !/^[a-zA-Z0-9]*$/.test(gstNumber)) {
      newErrors.gstNumber = "GST number must be alphanumeric"; // Error message for non-alphanumeric
    }
    
    if (!address) newErrors.address = "Address is required";
    
    if (!email) newErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Invalid email format";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Console log all form values
      console.log("Form values: ", {
        name,
        businessType,
        gstNumber,
        address,
        email,
        hasGst,
        phone,
      });
      // Logic for submitting the form 
    } else {
      console.log("Validation errors: ", errors); // Log validation errors
    }
  };

  return (
    <div className="page-content p-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <img
                    src={user1}
                    alt="User Profile"
                    className="avatar-md rounded-circle img-thumbnail me-3"
                  />
                  <div className="text-muted">
                    <h5>{name}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4 className="card-title mb-4">Merchant Profile</h4>

        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit} className="needs-validation">
              {/* Name Field */}
              <div className="mb-3">
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                />
                {errors.name && <div className="text-danger">{errors.name}</div>}
              </div>

              {/* Business Type Field */}
              <div className="mb-3">
                <label htmlFor="businessType">Business Type</label>
                <input
                  id="businessType"
                  type="text"
                  placeholder="Enter your business type"
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                  className={`form-control ${errors.businessType ? 'is-invalid' : ''}`}
                />
                {errors.businessType && <div className="text-danger">{errors.businessType}</div>}
              </div>

             
              {/* GST Number Field */}
              <div className="mb-3">
                <div className="form-check">
                  <input 
                  type="checkbox" 
                  onChange={handleGstToggle} 
                  className="form-check-input" 
                  />
                  
                    <label className="form-check-label mb-3">
                    I don't have a GST number
                    </label>
                    </div>
                    <label htmlFor="gstNumber">GST Number</label>
                    <input
                    id="gstNumber"
                    type="text"
                    placeholder="Enter GST number"
                    value={gstNumber}
                    onChange={(e) => {
                      // Restrict input to alphanumeric characters only
                      const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                      setGstNumber(value);
                    }}
                    disabled={!hasGst}
                    
                    onKeyDown={(e) => {
                      // Block special characters except alphanumeric
                      if (!/^[a-zA-Z0-9]*$/.test(e.key) && e.key !== "Backspace" && e.key !== "Tab") {
                        e.preventDefault();
                      }
                    }}
                    className={`form-control ${errors.gstNumber ? 'is-invalid' : ''}`}
                    />
                  </div>
                    {errors.gstNumber && <div className="text-danger">{errors.gstNumber}</div>}
                  
                    
                    {/* Address Field */}
              <div className="mb-3">
                <label htmlFor="address">Address</label>
                <input
                  id="address"
                  placeholder="Enter your address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                />
                {errors.address && <div className="text-danger">{errors.address}</div>}
              </div>

              {/* Phone Number Field */}
              <div className="mb-3">
                <label htmlFor="phone">Phone Number</label>
                <input
                  id="phone"
                  type="text"
                  
                  value={phone}
                  disabled
                  className="form-control"
                  style={{ backgroundColor: "#f0f0f0" }} 
                />
              </div>

              {/* Email Field */}
              <div className="mb-3">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                />
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </div>

              {/* Submit Button */}
              <button type="submit" className="btn btn-primary">
                Update Profile
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default (UserProfile);