
import React from "react";
import { Card, CardBody, CardText } from "reactstrap";


const companyLogoUrl = "Logo.png"; //  logo path
const qrCodeUrl = "qr-code.png"; //  QR code path

const MonthlyEarnings = props => {
    return (
        <React.Fragment>
            <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , borderLeft: '10px solid #AF2928', borderRight: '10px solid #AF2928' }}>
                <CardBody style={{ textAlign: 'center' }}>
                    

                    {/* Company Logo */}
                    <img src={companyLogoUrl} alt="Company Logo" className="mb-4" style={{ maxWidth: '200px' }} />

                    <CardText className="mb-2" style={{ fontSize: '1.5rem', fontWeight:'bold',color:'#AF2928', lineHeight: '1.1' }}>
                        PLEASE TAKE A MIN AND
                    </CardText>
                    <CardText className="mb-2" style={{ color:'#AF2928', fontWeight:'bold',fontSize: '1.5rem', lineHeight: '1.1', }}>
                        REVIEW US
                    </CardText>

                    {/* QR Code Image */}
                    <img src={qrCodeUrl} alt="QR Code" className="mb-2" style={{ maxWidth: '200px' }} />

                    {/* AI Powered Review Text */}
                    <CardText className="mb-2" style={{ fontSize: '1.3rem',fontWeight:'bold', lineHeight: '1.1' }}>WITH OUR ALL NEW</CardText>
                    <CardText className="mb-4">
                        <span style={{ backgroundColor: '#AF2928',fontWeight:'bold', color: '#fff', padding: '0 10px', fontSize: '1.5rem', lineHeight: '1.1', }}>
                            AI POWERED REVIEW
                        </span>
                    </CardText>

                    {/* Footer text */}
                    <CardText className="mb-2" style={{ fontSize: '1rem' }}>
                        <small>Powered By: PostReview</small>
                    </CardText>
                    
                </CardBody>       
            </Card>       
        </React.Fragment>
    );
};

export default MonthlyEarnings;
