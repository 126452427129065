import React, { useState } from "react"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap"

const AIReviewCreation = () => {
  const [activeStars, setActiveStars] = useState(0)
  const [clickedDishesKeywords, setClickedDishesKeywords] = useState([]) // Separate state for dish keywords
  const [clickedReviewKeywords, setClickedReviewKeywords] = useState([]) // State for review keywords
  const [generatedReview, setGeneratedReview] = useState("") // State to store the generated review
  const [showAllDishesKeywords, setShowAllDishesKeywords] = useState(false)
  const [showAllReviewKeywords, setShowAllReviewKeywords] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false) // Track form submission
  const [copySuccess, setCopySuccess] = useState(false)
  console.log(copySuccess)
  const dishesKeywords = [
    "Sandwiches",
    "Burger",
    "Pizza",
    "Salads",
    "Milkshakes",
    "Soft Drinks",
    "Cheese and Crackers",
    "Cold Brew",
    "Classic Hot Chocolate",
    "Muffins",
    "Fruit Cups",
    "Espresso",
    "Cappuccino",
  ]

  const reviewKeywords = [
    "Service",
    "Parking",
    "Staff",
    "Outdoor Seating",
    "Dine-In",
    "Charging Stations",
    "Kid-Friendly Amenities",
    "Specialty Orders",
    "Digital Menus",
    "Contactless Payment",
  ]

  // Array of rating descriptions
  const ratingDescriptions = [
    "Very Poor",
    "Poor",
    "Average",
    "Good",
    "Very Good",
  ]

  // Toggle dish keyword selection
  const toggleDishKeyword = keyword => {
    setClickedDishesKeywords(prev =>
      prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword],
    )
  }

  // Toggle review keyword selection
  const toggleReviewKeyword = keyword => {
    setClickedReviewKeywords(prev =>
      prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword],
    )
  }

  // Generate review with validation
  const generateReview = () => {
    setHasSubmitted(true) // Set submitted flag to true when the form is submitted

    if (
      activeStars === 0 ||
      clickedDishesKeywords.length === 0 ||
      clickedReviewKeywords.length === 0
    ) {
      return // Don't generate review if there are validation errors
    }

    const selectedDishes = clickedDishesKeywords.join(", ")
    const selectedReviewKeywords = clickedReviewKeywords.join(", ")
    const review = `Rating: ${ratingDescriptions[activeStars - 1]}. Recommended dishes: ${selectedDishes}. Review Keywords: ${selectedReviewKeywords}.`
    setGeneratedReview(review) // Set the generated review text
  }

  // Set star rating and log corresponding description to the console
  const handleStarClick = star => {
    setActiveStars(star)
    console.log(`Rating selected: ${ratingDescriptions[star - 1]}`) // Log to console
  }

  // Function to copy the review to clipboard and show success notification
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(generatedReview)
      .then(() => {
        setCopySuccess(true)
        setTimeout(() => setCopySuccess(false), 2000)
      })
      .catch(err => {
        console.error("Failed to copy: ", err)
      })
  }

  return (
    <Container className="my-2">
      {/* Header Section */}
      <Row className="text-center">
        <Col>
          <img
            src="Logo.jpg"
            alt="Logo"
            className="img-fluid"
            style={{ height: "140px" }}
          />
        </Col>
      </Row>
      {/* Star Rating Section */}
      <Row className="text-center mb-1">
        <Col>
          <div className="d-flex justify-content-center">
            {[1, 2, 3, 4, 5].map(star => (
              <i
                className={`mdi ${activeStars >= star ? "mdi-star" : "mdi-star-outline"}`}
                key={star}
                onClick={() => handleStarClick(star)}
                style={{
                  fontSize: "clamp(40px, 5vw, 80px)",
                  cursor: "pointer",
                  margin: "0 10px",
                  color: activeStars >= star ? "gold" : "gray",
                  transition: "transform 0.3s, color 0.3s",
                  transform:
                    activeStars >= star ? "rotate(50deg)" : "rotate(0deg)",
                }}
              />
            ))}
          </div>

          {hasSubmitted && activeStars === 0 && (
            <div style={{ color: "red" }}>Please select a star rating.</div>
          )}
        </Col>
      </Row>
      <Row className="mb-3" style={{ width: "100%", justifyContent: "center" }}>
        <Col xl="6">
          <div className="mb-4">
            <h4>Which dishes do you recommend?</h4>
            <div className="d-flex flex-wrap justify-content-start align-items-center">
              {dishesKeywords
                .slice(0, showAllDishesKeywords ? dishesKeywords.length : 6)
                .map(keyword => (
                  <div key={keyword} style={{ margin: "5px" }}>
                    <Button
                      className={`w-auto ${
                        clickedDishesKeywords.includes(keyword)
                          ? "btn-info"
                          : "btn-outline-secondary"
                      }`}
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: clickedDishesKeywords.includes(keyword)
                          ? "rgba(100, 60, 100, 0.1)"
                          : "rgba(255, 255, 255, 0)",
                        color: "black",
                        transition:
                          "background-color 0.3s, color 0.3s, font-weight 0.3s, border-color 0.3s",
                        fontWeight: clickedDishesKeywords.includes(keyword)
                          ? "bold"
                          : "normal",
                      }}
                      onMouseEnter={e => {
                        e.target.style.backgroundColor =
                          clickedDishesKeywords.includes(keyword)
                            ? "rgba(100, 60, 100, 0.1)" // Apply higher opacity on hover if clicked
                            : "rgba(100, 60, 100, 0.1)" // Apply blue with opacity for non-clicked
                        e.target.style.border = "2px solid gray" // Apply blue border on hover
                      }}
                      onMouseLeave={e => {
                        e.target.style.backgroundColor =
                          clickedDishesKeywords.includes(keyword)
                            ? "rgba(100, 60, 100, 0.1)" // Revert back to the original background if clicked
                            : "rgba(255, 255, 255, 0)" // Remove background if not clicked
                        e.target.style.border = "1px solid gray" // Reset border to gray
                      }}
                      onClick={() => toggleDishKeyword(keyword)}
                    >
                      <span
                        border="none"
                        role="img"
                        aria-label="check"
                        className="mr-2"
                        style={{
                          color: clickedDishesKeywords.includes(keyword)
                            ? "#7A6FBE"
                            : "transparent",
                        }}
                      >
                        {clickedDishesKeywords.includes(keyword) ? "✔️" : ""}
                      </span>
                      {keyword}
                    </Button>
                  </div>
                ))}
              {!showAllDishesKeywords && dishesKeywords.length > 6 && (
                <div style={{ margin: "5px" }}>
                  <Button
                    color="link"
                    onClick={() =>
                      setShowAllDishesKeywords(!showAllDishesKeywords)
                    }
                    style={{
                      border: "1px solid gray",
                      borderRadius: "5px",
                      padding: "10px 15px",
                      backgroundColor: "rgba(100, 60, 100, 0.1)",
                      color: "#014f6d",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  >
                    {showAllDishesKeywords
                      ? "Show Less"
                      : `+${dishesKeywords.length - 6}`}
                  </Button>
                </div>
              )}
            </div>
            {hasSubmitted && clickedDishesKeywords.length === 0 && (
              <div style={{ color: "red" }}>
                Please select at least one dish keyword.
              </div>
            )}
          </div>

          <div className="mb-4">
            <h4>Keywords you want to use in this review?</h4>
            <div className="d-flex flex-wrap justify-content-start align-items-center">
              {reviewKeywords
                .slice(0, showAllReviewKeywords ? reviewKeywords.length : 6)
                .map(keyword => (
                  <div key={keyword} style={{ margin: "5px" }}>
                    <Button
                      className={`w-auto ${
                        clickedReviewKeywords.includes(keyword)
                          ? "btn-info"
                          : "btn-outline-secondary"
                      }`}
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: clickedReviewKeywords.includes(keyword)
                          ? "rgba(100, 60, 100, 0.1)"
                          : "rgba(255, 255, 255, 0)",
                        color: "black",
                        transition:
                          "background-color 0.3s, color 0.3s, font-weight 0.3s",
                        fontWeight: clickedReviewKeywords.includes(keyword)
                          ? "bold"
                          : "normal",
                      }}
                      onMouseEnter={e => {
                        e.target.style.backgroundColor =
                          clickedReviewKeywords.includes(keyword)
                            ? "rgba(100, 60, 100, 0.1)" // Slightly darker purple with opacity
                            : "rgba(100, 60, 100, 0.1)" // Blue background with opacity
                        e.target.style.border = "2px solid gray" // Apply blue border on hover
                      }}
                      onMouseLeave={e => {
                        e.target.style.backgroundColor =
                          clickedReviewKeywords.includes(keyword)
                            ? "rgba(100, 60, 100, 0.1)" // Revert to original background if clicked
                            : "rgba(255, 255, 255, 0)" // No background if not clicked
                        e.target.style.border = "1px solid gray" // Reset border to gray
                      }}
                      onClick={() => toggleReviewKeyword(keyword)}
                    >
                      <span
                        border="none"
                        role="img"
                        aria-label="check"
                        className="mr-2"
                        style={{
                          color: clickedReviewKeywords.includes(keyword)
                            ? "#7A6FBE"
                            : "transparent",
                        }}
                      >
                        {clickedReviewKeywords.includes(keyword) ? "✔️" : ""}
                      </span>
                      {keyword}
                    </Button>
                  </div>
                ))}
              {!showAllReviewKeywords && reviewKeywords.length > 6 && (
                <div style={{ margin: "5px" }}>
                  <Button
                    color="link"
                    onClick={() =>
                      setShowAllReviewKeywords(!showAllReviewKeywords)
                    }
                    style={{
                      border: "1px solid gray",
                      borderRadius: "5px",
                      padding: "10px 15px",
                      backgroundColor: "rgba(100, 60, 100, 0.1)",
                      color: "#014f6d",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  >
                    {showAllReviewKeywords
                      ? "Show Less"
                      : `+${reviewKeywords.length - 6}`}
                  </Button>
                </div>
              )}
            </div>
            {hasSubmitted && clickedReviewKeywords.length === 0 && (
              <div style={{ color: "red" }}>
                Please select at least one review keyword.
              </div>
            )}
          </div>
        </Col>
      </Row>

      <style>
        {`
          @media (max-width: 600px) {
            i.mdi {
              font-size: 10vw; 
            }
          }
           
        `}
      </style>

      {/* Generate Review Button */}

      <Row className="justify-content-center text-center">
        <Col xs="12" md="6" lg="4">
          <Button
            color="primary"
            onClick={generateReview}
            style={{ fontSize: "18px", padding: "10px 10px", width: "100%" }} // Full width for responsiveness
          >
            Generate Review
          </Button>
        </Col>
      </Row>

      <Row className="mt-4 d-flex justify-content-center">
        <Col xl="8" lg="10" md="12">
          <Card className="border-0" style={{ minHeight: "150px" }}>
            <CardBody>
              <h5>Generated Review:</h5>
              <p>{generatedReview}</p>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center text-center">
        <Col xs="12" md="6" lg="4">
          <Button
            color="primary"
            onClick={copyToClipboard}
            style={{ fontSize: "18px", padding: "10px 10px", width: "100%" }} // Full width for responsiveness
          >
            Copy & Post
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default AIReviewCreation
