import React, { useEffect } from "react"

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"
//import EmailSent from "./email-sent"
//import MonthlyEarnings2 from "./montly-earnings2"
// import Inbox from "./inbox";
// import RecentActivity from "./recent-activity";
// import WidgetUser from "./widget-user";
// import YearlySales from "./yearly-sales";
// import LatestTransactions from "./latest-transactions";
// import LatestOrders from "./latest-orders";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

const Dashboard = props => {
  document.title = "Dashboard | Lexa - Responsive Bootstrap 5 Admin Dashboard"

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Dashboard", breadcrumbItems)
  })

  const reports = [
    // {
    //   title: "Orders",
    //   iconClass: "cube-outline",
    //   total: "1,587",
    //   average: "+11%",
    //   badgecolor: "info",
    // },
    // {
    //   title: "Revenue",
    //   iconClass: "buffer",
    //   total: "$46,782",
    //   average: "-29%",
    //   badgecolor: "danger",
    // },
    // {
    //   title: "Average Price",
    //   iconClass: "tag-text-outline",
    //   total: "$15.9",
    //   average: "0%",
    //   badgecolor: "warning",
    // },
    // {
    //   title: "Product Sold",
    //   iconClass: "briefcase-check",
    //   total: "1890",
    //   average: "+89%",
    //   badgecolor: "info",
    // },
  { title: "Positive Reviews", iconClass: "thumb-up", total: "125", average: "+15%", badgecolor: "success" },
  { title: "Negative Reviews", iconClass: "thumb-down", total: "50", average: "-5%", badgecolor: "danger" },
  { title: "Total QR Code Scans", iconClass: "qrcode-scan", total: "150", average: "+20%", badgecolor: "primary" },
  ]

  return (
    <React.Fragment>
      {/*mimi widgets */}
      <Miniwidget reports={reports} />

      <Row>
        <Col xl="4">
          {/* Monthly Earnings */}
          <MonthlyEarnings />
        </Col>

        
      </Row>
      {/* <Row>
        <Col xl="4" lg="6">
        
          <Inbox />
        </Col>
        <Col xl="4" lg="6">
          
          <RecentActivity />
        </Col>
        <Col xl="4">
          
          <WidgetUser />

          
          <YearlySales />
        </Col>
      </Row> */}

      {/* <Row>
        <Col xl="6">
          
          <LatestTransactions />
        </Col>

        <Col xl="6">
         
          <LatestOrders />
        </Col>
      </Row> */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
