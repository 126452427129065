import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"

import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import Logo from "../../assets/images/Logo.png"
import { resetProfileFlag } from "../../store/actions"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"

const NegativeReviewForm = props => {
  document.title = "Profile | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [reviewSummary, setReviewSummary] = useState("")
  console.log(setPhone)
  console.log(setReviewSummary)
  // New state for tracking submission status
  const [submitMessage, setSubmitMessage] = useState("")

  const selectProfileState = state => state.Profile
  const ProfileProperties = createSelector(selectProfileState, profile => ({
    success: profile.success,
  }))

  const { error, success } = useSelector(ProfileProperties)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setName(obj.username)

      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: name || "",
      phone: phone || "",
      reviewSummary: reviewSummary || "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .matches(/^[A-Za-z ]*$/, "Name should contain only letters")
        .required("Please Enter Your Name"),
      phone: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
        .required("Please Enter Phone Number"),
      reviewSummary: Yup.string().required("Please Enter Review Summary"),
    }),
    onSubmit: values => {
      setSubmitMessage("Review submitted successfully!")

      validation.resetForm()

      console.log("Submitted values:", values)
    },
  })

  return (
    <React.Fragment>
      <Row>
        <Col xl="12">
          <Col xl="6">
            {error && error ? <Alert color="danger">{error}</Alert> : null}
            {success ? <Alert color="success">{success}</Alert> : null}

            {submitMessage && (
              <Alert
                color="success"
                style={{ backgroundColor: "#d4edda", borderColor: "#c3e6cb" }}
              >
                {submitMessage}
              </Alert>
            )}
          </Col>

          <Card>
            <CardBody>
              <div className="text-center mb-4">
                <img
                  src={Logo}
                  alt="Merchant Logo"
                  className="img-fluid"
                  style={{ maxWidth: "300px" }}
                />
              </div>
              <h4
                className="card-title mb-4 text-left"
                style={{ padding: "0px 30px" }}
              >
                We're Sorry to Hear That
              </h4>
              <p style={{ padding: "0px 30px" }}>
                Please let us know what went wrong, and we'll do our best to fix
                it.
              </p>
              <Form
                style={{ padding: "0px 30px" }}
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group mt-3">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder="Enter Your Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group mt-3">
                  <Label className="form-label">Phone Number</Label>
                  <Input
                    name="phone"
                    className="form-control"
                    placeholder="Enter Your Phone Number"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone || ""}
                    invalid={
                      validation.touched.phone && validation.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group mt-3">
                  <Label className="form-label">
                    AI-generated Review Summary
                  </Label>
                  <Input
                    style={{ height: "150px" }}
                    name="reviewSummary"
                    className="form-control"
                    placeholder="Enter Your Review"
                    type="textarea"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.reviewSummary || ""}
                    invalid={
                      validation.touched.reviewSummary &&
                      validation.errors.reviewSummary
                        ? true
                        : false
                    }
                  />
                  {validation.touched.reviewSummary &&
                  validation.errors.reviewSummary ? (
                    <FormFeedback type="invalid">
                      {validation.errors.reviewSummary}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="text-center mt-4">
                  <Button
                    type="submit"
                    color="primary"
                    disabled={!validation.isValid || validation.isSubmitting}
                  >
                    Submit Review
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(NegativeReviewForm)
