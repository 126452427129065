import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from 'formik';
import { Button,
   Label, 
   Input, 
   FormFeedback, 
   Row, 
   Col, 
   Alert } from 'reactstrap';
import * as Yup from 'yup';
import { SketchPicker } from 'react-color';
import Switch from "react-switch";

// Validation schema using Yup
const validationSchema = Yup.object({
  logo: Yup.mixed().required('Logo is required')
  .test("fileSize", "File size is too large. Please select an image below 500KB", (value) => {
    return value && value.size <= 500000; // 500kb
  }),
  themeColor: Yup.string().required('Theme Color is required'),
  textColor: Yup.string().required('Text Color is required'),
  question1: Yup.string().required('Question 1 is required'),
  keyword1: Yup.array().min(1, 'At least one keyword is required'), // Validate as an array
  question2: Yup.string().required('Question 2 is required'),
  keyword2: Yup.array().min(1, 'At least one keyword is required'), // Validate as an array
  googleReviewLink: Yup.string()
    .required('Google Review Link is required')
    .url('Enter a valid URL'),
  negativeReviewMessage: Yup.string().required('Negative Review Message is required'),
});

const MerchantProfileForm = () => {
  const [logoPreview, setLogoPreview] = useState(null);
  const [themeColor, setThemeColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [showThemePicker, setShowThemePicker] = useState(false);
  const [showTextPicker, setShowTextPicker] = useState(false);
  const [switch1, setswitch1] = useState(true);
  const [submitMessage, setSubmitMessage] = useState("")

  
  const themePickerRef = useRef(null);
  const textPickerRef = useRef(null);

  const handleLogoChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
        setFieldValue("logo", file);
      };
      reader.readAsDataURL(file);
    } else {
      setLogoPreview(null);
      setFieldValue("logo", null);
    }
  };

  const handleThemeColorChange = (color, setFieldValue) => {
    setThemeColor(color.hex);
    setFieldValue("themeColor", color.hex);
  };

  const handleTextColorChange = (color, setFieldValue) => {
    setTextColor(color.hex);
    setFieldValue("textColor", color.hex);
  };
  const OnSymbol = () => (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      fontSize: 12,
      color: "#fff",
      paddingRight: 2
    }}> On </div>
  );

  const Offsymbol = () => (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      fontSize: 12,
      color: "#fff",
      paddingRight: 2
    }}> Off </div>
  );


  const handleSubmit = (values) => {
    // Submit logic here
    setSubmitMessage("Data submitted successfully!")
    console.log('Form data:', values);
  };

  // Effect to handle clicks outside the color picker
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (themePickerRef.current && !themePickerRef.current.contains(event.target)) {
        setShowThemePicker(false);
      }
      if (textPickerRef.current && !textPickerRef.current.contains(event.target)) {
        setShowTextPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Formik
      initialValues={{
        logo: null,
        themeColor: '',
        textColor: '',
        question1: '',
        keyword1: [], 
        question2: '',
        keyword2: [], 
        googleReviewLink: '',
        negativeReviewMessage: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, setFieldValue,setFieldTouched, isSubmitting }) => (
        <Form>
          {/* Row for Logo Upload and Color Pickers */}
          <Row>
            <Col md="6">
            {submitMessage && (
              <Alert
                color="success"
                style={{ backgroundColor: "#d4edda", borderColor: "#c3e6cb" }}
              >
                {submitMessage}
              </Alert>
            )}
              {/* Logo Upload */}
              <div className="form-group mt-3">
                <Label>Upload Your Logo</Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    name="logo"
                    type="file"
                    accept="image/jpeg,image/png"
                    onChange={(event) => handleLogoChange(event, setFieldValue)}
                    id="logoUpload"
                    style={{ display: "none" }} // Hidden file input
                  />
                  <Button color="primary" onClick={() => document.getElementById('logoUpload').click()}>
                    Upload Logo
                  </Button>
                  <span style={{ marginLeft: "10px" }}>
                    {logoPreview ? "Logo uploaded!" : "No logo uploaded."}
                  </span>
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      border: "1px solid #ccc",
                      borderRadius: "3px",
                      marginLeft: "20px",
                      backgroundColor: "#f5f5f5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {logoPreview ? (
                      <img
                        src={logoPreview}
                        alt="Logo Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <span style={{ color: "#ccc" }}>No logo</span>
                    )}
                  </div>
                </div>
                {touched.logo && errors.logo ? (
                  <FormFeedback style={{ display: "block" }}>{errors.logo}</FormFeedback>
                ) : null}
              </div>
              </Col>
  </Row>

              {/* Theme and Text Color Pickers */}
             
              <Row>
                <Col md="6">
                <div className="mb-3" style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}>
                  <div style={{ marginRight: "20px", flex: "1 1 200px" }}>
                    {/* Theme Color Picker */}
                    <Label>Theme Color</Label>
                    <Input
                    type="text"
                    placeholder="Select Theme Color"
                    onClick={() => setShowThemePicker(!showThemePicker)}
                    value={themeColor}
                    readOnly
                    style={{ width: "100%" }} // Make the input field full width
                    invalid={touched.themeColor && !!errors.themeColor}
                     />
                     {showThemePicker && (
                      <div ref={themePickerRef}>
                        <SketchPicker color={themeColor} onChangeComplete={(color) => handleThemeColorChange(color, setFieldValue)} />

                        </div>
                      )}
                      {touched.themeColor && errors.themeColor ? (
                        <FormFeedback style={{ display: "block" }}>{errors.themeColor}</FormFeedback>
                        ) : null}
                        {/* Text Color Picker */}
                        <Label className="mt-3">Text Color</Label>
                        <Input
                        type="text"
                        placeholder="Select Text Color"
                        onClick={() => setShowTextPicker(!showTextPicker)}
                        value={textColor}
                        readOnly
                        style={{ width: "100%" }} // Make the input field full width
                        invalid={touched.textColor && !!errors.textColor}
                        />
                        {showTextPicker && (
                          <div ref={textPickerRef}>
                            <SketchPicker color={textColor} onChangeComplete={(color) => handleTextColorChange(color, setFieldValue)} />

                            </div>
                          )}
                          {touched.textColor && errors.textColor ? (
                            <FormFeedback style={{ display: "block" }}>{errors.textColor}</FormFeedback>
                            ) : null}
                            </div>
                            {/* Button and Preview Container */}
                            <div style={{
                              marginTop: "20px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              padding: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent:"center",
                              flex: "1 1 150px",
                               }}>
                                <Button
                                 disabled // Disable the button
                                    style={{
                                    backgroundColor: themeColor,
                                    borderColor: themeColor,
                                    color: textColor,
                                    marginRight: "10px", // Add margin for spacing
                                    whiteSpace: "nowrap", // Prevent text wrapping
                                      }}>
                                   Post Review
                                  </Button>
                                  {/* Vertical Line */}
                                  <div style={{
                                    borderLeft: "1px solid #ccc", // Color of the vertical line
                                    height: "60px", // Height of the vertical line
                                    margin: "0 10px", // Margin for spacing
                                    }}></div>

                                  <div style={{
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "lighter",
                                  }}>
                                 This is a preview button and it will be visible to the person submitting review.
                                 </div>
                      </div>
                   </div>
                  </Col>
                </Row>

          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="question1">Enter Your First Question.</Label>
                <Input
                  type="text"
                  name="question1"
                  id="question1"
                  placeholder="Enter your first question"
                  value={values.question1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.question1 && !!errors.question1}
                />
                {touched.question1 && errors.question1 && (
                  <FormFeedback>{errors.question1}</FormFeedback>
                )}
              </div>

              {/* Keywords for Question 1 */}
              <div className="mb-3">
  <Label htmlFor="keyword1">Keywords for First Question. </Label>
  <small className="text-muted d-block mb-1">Separate each keyword using a comma or press Enter</small>
  <div className="token-input-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
    {Array.isArray(values.keyword1) && values.keyword1.map((keyword, index) => (
      <div key={index} className="token" style={{ backgroundColor: '#C7C8CC', padding: '4px 8px', borderRadius: '4px', margin: '2px', display: 'inline-flex', alignItems: 'center' }}>
        {keyword}
        <span
          className="token-remove"
          style={{ marginLeft: '8px', cursor: 'pointer', color: 'black' }}
          onClick={() => setFieldValue('keyword1', values.keyword1.filter(k => k !== keyword))}
        >
          &times;
        </span>
      </div>
    ))}
    <Input
      type="text"
      name="keywords" 
      placeholder="Enter keywords"
      onKeyDown={(e) => {
        const newKeyword = e.target.value.trim(); // Trim to avoid accidental spaces
        if (e.key === 'Enter' || e.key === ',') {
          e.preventDefault();
          if (newKeyword && !values.keyword1.includes(newKeyword)) {
            setFieldValue('keyword1', [...values.keyword1, newKeyword]);
            e.target.value = '';
          }
        }
      }}
      onBlur={(e) => {
        const newKeyword = e.target.value.trim(); // Trim to avoid accidental spaces
        if (newKeyword && !values.keyword1.includes(newKeyword)) {
          setFieldValue('keyword1', [...values.keyword1, newKeyword]);
          e.target.value = '';
        }
        setFieldTouched('keyword1', true); // Manually set the field as touched
        handleBlur(e); // Ensure Formik's handleBlur is called
      }}
      className={`token-input-field ${touched.keyword1 && errors.keyword1 ? 'is-invalid' : ''}`}
    />
  </div>
  {touched.keyword1 && errors.keyword1 && (
    <FormFeedback style={{ display: 'block' }}>{errors.keyword1}</FormFeedback>
  )}
              </div>
              
               {/* Question 2 */}

              <div className="mb-3">
                <Label htmlFor="question2">Enter Your Second Question.</Label>
                <Input
                  type="text"
                  name="question2"
                  id="question2"
                  placeholder="Enter your second question"
                  value={values.question2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.question2 && !!errors.question2}
                />
                {touched.question2 && errors.question2 && (
                  <FormFeedback>{errors.question2}</FormFeedback>
                )}
              </div>

              {/* Keywords for Question 2 */}
              <div className="mb-3">
  <Label htmlFor="keyword2">Keywords for Second Question. </Label>
  <small className="text-muted d-block mb-1">Separate each keyword using a comma or press Enter</small>
  <div className="token-input-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
    {Array.isArray(values.keyword2) && values.keyword2.map((keyword, index) => (
      <div key={index} className="token" style={{ backgroundColor: '#C7C8CC', padding: '4px 8px', borderRadius: '4px', margin: '2px', display: 'inline-flex', alignItems: 'center' }}>
        {keyword}
        <span
          className="token-remove"
          style={{ marginLeft: '8px', cursor: 'pointer', color: 'black' }}
          onClick={() => setFieldValue('keyword2', values.keyword2.filter(k => k !== keyword))}
        >
          &times;
        </span>
      </div>
    ))}
    <Input
      type="text"
      name="keywords" 
      placeholder="Enter keywords"
      onKeyDown={(e) => {
        const newKeyword = e.target.value.trim(); // Trim to avoid accidental spaces
        if (e.key === 'Enter' || e.key === ',') {
          e.preventDefault();
          if (newKeyword && !values.keyword2.includes(newKeyword)) {
            setFieldValue('keyword2', [...values.keyword2, newKeyword]);
            e.target.value = '';
          }
        }
      }}
      onBlur={(e) => {
        const newKeyword = e.target.value.trim(); // Trim to avoid accidental spaces
        if (newKeyword && !values.keyword2.includes(newKeyword)) {
          setFieldValue('keyword2', [...values.keyword2, newKeyword]);
          e.target.value = '';
        }
        setFieldTouched('keyword2', true); // Manually set the field as touched
        handleBlur(e); // Ensure Formik's handleBlur is called
      }}
      className={`token-input-field ${touched.keyword2 && errors.keyword2 ? 'is-invalid' : ''}`}
    />
  </div>
  {touched.keyword2 && errors.keyword2 && (
    <FormFeedback style={{ display: 'block' }}>{errors.keyword2}</FormFeedback>
  )}
              </div>

              

              <div className="mb-3">
                <Label htmlFor="googleReviewLink">Google Review Link</Label>
                <Input
                  type="url"
                  name="googleReviewLink"
                  id="googleReviewLink"
                  placeholder="Enter your Google Review Link"
                  value={values.googleReviewLink}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.googleReviewLink && !!errors.googleReviewLink}
                />
                {touched.googleReviewLink && errors.googleReviewLink && (
                  <FormFeedback>{errors.googleReviewLink}</FormFeedback>
                )}
              </div>

              <div className="mb-3">
                <Label htmlFor="negativeReviewMessage">Negative Review Message</Label>
                <Input
                  type="textarea"
                  name="negativeReviewMessage"
                  id="negativeReviewMessage"
                  placeholder="Enter message for negative reviews"
                  value={values.negativeReviewMessage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.negativeReviewMessage && !!errors.negativeReviewMessage}
                />
                {touched.negativeReviewMessage && errors.negativeReviewMessage && (
                  <FormFeedback>{errors.negativeReviewMessage}</FormFeedback>
                )}
              </div>

               {/* Negative Review Protection */}
               <div className="form-group mt-3">
                <Label className="form-label">Enable Negative Review Protection</Label>
                <br />
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor="#7A6FBE"
                  onChange={() => setswitch1(!switch1)}
                  checked={switch1}
                />
                &nbsp;
              </div>

             
            </Col>
          </Row>
           {/* Submit Button */}
           <Button type="submit" color="primary" className="mt-3" disabled={isSubmitting}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default MerchantProfileForm;
